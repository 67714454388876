@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}


.card{
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
.expenses-filter {
    color: white;
    padding: 0 1rem;
  }
  
  .expenses-filter__control {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  }
  
  .expenses-filter label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .expenses-filter select {
    font: inherit;
    padding: 0.5rem 3rem;
    font-weight: bold;
    border-radius: 6px;
  }
.expense-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: #4a7791;
  }
  
  .expense-item__description {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1 1;
  }
  
  .expense-item h2 {
    color: #3a3a3a;
    font-size: 1rem;
    flex: 1 1;
    margin: 0 1rem;
    color: white;
  }
  
  .expense-item__price {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #559b70;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;
  }
  
  @media (min-width: 580px) {
    .expense-item__description {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1;
    }
  
    .expense-item__description h2 {
      font-size: 1.25rem;
    }
  
    .expense-item__price {
      font-size: 1.25rem;
      padding: 0.5rem 1.5rem;
    }
  }






.expense-date {
    display: flex;
    flex-direction: column;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #ececec;
    background-color: #2a2a2a;
    color: white;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
  }
  
  .expense-date__day {
    font-size: 0.75rem;
    font-weight: bold;
  }
  
  .expense-date__month {
    font-size: 0.75rem;
  }
  
  .expense-date__year {
    font-size: 1.5rem;
    font-weight: bold;
  }


.expenses-list {
    list-style: none;
    padding: 0;
  }
  
  .expenses-list__fallback {
    color: white;
    text-align: center;
  }
.chart-bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-bar__inner {
    height: 100%;
    width: 100%;
    border: 1px solid #313131;
    border-radius: 12px;
    background-color: #aac2d6;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .chart-bar__fill {
    background-color: #d16d3f;
    width: 100%;
    transition: all 0.8s ease-out;
  }
  
  .chart-bar__label {
    font-weight: bold;
    font-size: 0.5rem;
    text-align: center;
  }
.chart {
    padding: 1rem;
    border-radius: 12px;
    background-color: #47778a;
    text-align: center;
    display: flex;
    justify-content: space-around;
    height: 12rem;
  }

.expenses {
    padding: 1rem;
    background-color: #89acc4;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
  }

.new-expense {
    background-color: #4a7791;
    padding: 1rem;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  
  .new-expense button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid white;
    background-color: #89acc4;
    color: white;
    border-radius: 12px;
    margin-right: 1rem;
  }
  
  .new-expense button:hover,
  .new-expense button:active {
    background-color: #559b70;
    border-color: #559b70;
  }
  
  .new-expense button.alternative {
    color: #559b70;
    border-color: transparent;
    background-color: transparent;
  }
  
  .new-expense button.alternative:hover,
  .new-expense button.alternative:active {
    background-color: #87be9c;
  }
.new-expense__controls {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .new-expense__control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .new-expense__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 20rem;
    max-width: 100%;
  }
  
  .new-expense__actions {
    text-align: right;
  }
