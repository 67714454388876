

.expense-date {
    display: flex;
    flex-direction: column;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #ececec;
    background-color: #2a2a2a;
    color: white;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
  }
  
  .expense-date__day {
    font-size: 0.75rem;
    font-weight: bold;
  }
  
  .expense-date__month {
    font-size: 0.75rem;
  }
  
  .expense-date__year {
    font-size: 1.5rem;
    font-weight: bold;
  }

